<template>
  <v-dialog max-width="600px" v-model="dialog">
    <template v-slot:activator="{ on }">
      <v-btn @click.prevent v-on="on" fab dark bottom left fixed color="error" class="ma-2">
        <!-- <v-icon>mdi-bug</v-icon> -->
        <v-icon>mdi-comment-alert</v-icon>
        <!-- <v-icon>mdi-bullhorn-outline</v-icon> -->
        <!-- <v-icon>mdi-bugle</v-icon> -->
        <!-- <v-icon>mdi-air-horn</v-icon> -->
      </v-btn>
    </template>
    <v-card class="pb-5">
      <v-card-title> Report Bug | Give Feedback</v-card-title>
      <v-card-text>
        <p>Here you can report a bug, or even give us a suggestion or idea!</p>
        <p>
          When reporting a bug please let us the steps that you took to cause it so that we can reproduce and fix it :)
        </p>

        <v-form ref="feedback_form" v-model="valid">
          <v-container class="px-0">
            <v-row>
              <v-col cols="12">
                <v-text-field label="Title" v-model="title" :rules="[rules.required]" ref="focus"></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-textarea label="Details" v-model="details" filled></v-textarea>
              </v-col>
            </v-row>
            <v-row no-gutters>
              <v-col cols="12">
                Personal information to include:
                <v-switch v-model="include_name" label="Name" class="my-3" hide-details=""></v-switch>
                <v-switch v-model="include_email" label="Email" class="my-3" hide-details=""></v-switch>
                Providing email allows us to reach out for more information (if needed) and provide details on when the
                issue is resolved.
              </v-col>
            </v-row>
          </v-container>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-btn @click="submitIssue" block color="primary" :loading="saving" :disabled="saving">Submit Report</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
// import { firestore, FieldValue } from "@/fb"
// import { debug } from "@/logger"
// import { fetchData } from "@/food_api"
// import { mapActions } from "vuex"
import { request } from "@octokit/request"
export default {
  components: {},
  props: [],
  data() {
    return {
      dialog: false,
      valid: false,
      saving: false,
      title: "",
      details: "",
      include_name: false,
      include_email: false,
      // validation rules
      rules: {
        required: (value) => (!!value && value.length > 0) || "Required"
      }
    }
  },
  computed: {
    user() {
      return this.$store.getters["user"]
    }
  },
  methods: {
    // Personal access token (public_repo access): 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
    async submitIssue() {
      if (this.$refs.feedback_form.validate()) {
        this.saving = true
        // console.log(process.env.VUE_APP_TESTING)
        const requestWithAuth = request.defaults({
          headers: {
            authorization: "token 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"
          },
          owner: "JTensai",
          repo: "shelf-issues"
        })

        let detailsWithPersonalInfo = ""

        if (this.include_name == true) {
          detailsWithPersonalInfo = `Name: ${this.user.name} \n`
        }
        if (this.include_email == true) {
          detailsWithPersonalInfo = detailsWithPersonalInfo + `Email: ${this.user.email} \n`
          // might be nice to send them an email if they provided it giving them a link to the issue created and also reinforcing the fact that we will look into it.
        }
        detailsWithPersonalInfo =
          detailsWithPersonalInfo +
          (!!detailsWithPersonalInfo && detailsWithPersonalInfo.length > 0 ? "\n\n" : "") +
          this.details

        detailsWithPersonalInfo =
          detailsWithPersonalInfo +
          (detailsWithPersonalInfo.length > 0 ? "\n\n" : "") +
          `Auto collected this.$route information: \n\n`

        for (const key of Object.keys(this.$route)) {
          let json = "circular reference detected, could not stringify"
          try {
            json = JSON.stringify(this.$route[key])
          } catch (error) {
            console.warn("Could not stringify: " + key)
          }

          // some jankiness to get tabs to line up. Should be based on the max length of the keys, but i don't care right now enough to do that.
          detailsWithPersonalInfo =
            detailsWithPersonalInfo + key + ":" + "\t".repeat(key.length < 7 ? 3 : 2) + json + "\n"
        }
        // console.log(detailsWithPersonalInfo)
        try {
          await requestWithAuth("POST /repos/{owner}/{repo}/issues", {
            title: this.title,
            body: detailsWithPersonalInfo
          })
          this.saving = false
          this.dialog = false
          this.$refs.feedback_form.reset()
          this.$root.$emit("show-snackbar", "Success 😄 Thanks for the feedback!", 10000)
        } catch (error) {
          console.error(
            // error.message seems to actually access this: error.response.data.message
            `Failed to submit issue: "${error.name}: ${error.message}". We have been notified of the problem and are looking into it.`
          )
          // error keys:  "name", "status", "response", "request"
          // console.log(error.status)
          // console.log(error.response)
          // console.log(error.request)

          this.$root.$emit(
            "show-snackbar",
            "Failed to Submit 😢 Something went wrong. We should be aware of this and are working to fix this system.",
            10000
          )
        }
      }
    }
  }
}
</script>
