import Vue from "vue"
import Vuex from "vuex"
import { vuexfireMutations } from "vuexfire"

// import auth from './auth';
import data from "./data"
// import recipeBooks from './recipeBooks'
// import recipes from './recipes'

Vue.use(Vuex)

const store = new Vuex.Store({
  mutations: vuexfireMutations,
  modules: {
    // auth,
    data
    // recipeBooks,
    // recipes,
  }
  // state: {
  //   currentUser: null,
  //   recipeBooks: []
  // },
  // mutations: {
  //   ...vuexfireMutations
  // },
  // actions: {

  //   initRecipeBooks: firestoreAction(async ({ bindFirestoreRef }, userId) => {
  //     await bindFirestoreRef('recipeBooks', firestore.collection("users").doc(userId).collection("recipe_books"));

  //     const store = this.$store
  //     if (!(store && store.state && store.state.recipeBooks && store.state.recipeBooks[this.id])){
  //       store.registerModule(["recipeBooks", this.id], RecipeBooksModule);
  //     }
  //   }),
  //   bindRecipeBooks: firestoreAction(({ bindFirestoreRef }) => {
  //     debug("recipe books are bound");
  //     return bindFirestoreRef('recipeBooks', firestore.collection("users").doc(this.state.currentUser).collection("recipe_books"));
  //   })
  // }
})

export default store
