/*
NOTES:

all actions have 2 params: context, payload
context = {commit, dispatch, state, rootState, etc.}
payload = user defined object or array



*/

import Vue from "vue"
import { firestoreAction /* firestoreOptions */ } from "vuexfire"
// firestoreOptions.wait = true;

import { firestore, storage, FieldValue } from "@/fb"
import { debug, warn, log } from "@/logger"
debug("data: VUEX Data initializing")
const increment = FieldValue.increment(1)
const decrement = FieldValue.increment(-1)

// should probably have helper functions for adding things to the batch
// this will let me more easily add in the 500 operation limit check to each batch
// eg. trying to add a delete to a batch that already has 500 mutations will cause the batch
// to commit itself and then create a new one and then add that delete as the first mutation
// this may be a non issue if I can make it so there are never 500 mutations in an action
// but since I am allowing deleting an entire pantry or recipe book, the batch might get too big
// I'd have no other option except to split the batch up.

const state = {
  user: null,
  batch: firestore.batch(),
  searchQuery: null,
  currentRecipe: null,
  pantriesAreBound: null,
  recipeBooks: {
    docs: []
    // docs stores the references to the firebase sync'ed objects, these docs should contain all info needed for preview cards
    // docs: [fwbF1UI3dI49FOhp2R1U, vRjXjOvNhQtzIZGZ9VGh],
    // fwbF1UI3dI49FOhp2R1U: {
    //   members: [],
    //   recipes: {                             // state.recipeBooks[fwbF1UI3dI49FOhp2R1U].recipes
    //     docs: [],                            // state.recipeBooks[fwbF1UI3dI49FOhp2R1U].recipes.docs
    // These objects listed at keys of the recipe ID hold reference to subcollections and local data
    //     aberugyslasdkfylasdfj: {             // state.recipeBooks[fwbF1UI3dI49FOhp2R1U].recipes[aberugyslasdkfylasdfj]
    //       name: "First Recipe",              // state.recipeBooks[fwbF1UI3dI49FOhp2R1U].recipes[aberugyslasdkfylasdfj].name
    //       ingredients: [],
    //     },
    //     aberugyslasdkfylasdfj: {
    //       name: "Second Recipe",
    //       ingredients: [],
    //     }
    //   }
    // },
    // vRjXjOvNhQtzIZGZ9VGh: {
    //   members: []
    // }
  },
  pantries: {
    docs: []
    // fwbF1UI3dI49FOhp2R1U: {    // a pantry document
    //   members: [],
    //   // Need to do pantries the same way because they have subcollections too
    //   pantryItems: {                   // state.pantries[fwbF1UI3dI49FOhp2R1U].pantryItems
    //     docs: [],                      // state.pantries[fwbF1UI3dI49FOhp2R1U].pantryItems.docs
    //     aberugyslasdkfylasdfj: {       // state.pantries[fwbF1UI3dI49FOhp2R1U].pantryItems[aberugyslasdkfylasdfj]
    //       name: "First Item",        // state.pantries[fwbF1UI3dI49FOhp2R1U].pantryItems[aberugyslasdkfylasdfj].name
    //       quantity: 2,
    //       measurement: "tsp",
    //     },
    //     aberugyslasdkfylasdfj: {
    //       name: "Second Item",
    //       quantity: 2,
    //       measurement: "cup",
    //     }
    //   }
    // },
  },
  pantryItemBeingEdited: {},
  discoveredRecipes: {},
  lastDiscoveredRecipe: null,
  moreToDiscover: true
}

const getters = {
  user: (state) => {
    return state.user
  },
  colors: (state) => {
    return state.user ? state.user.colors : null
  },
  isLoggedIn: (state) => {
    return !!state.user
  },
  pantryItemOptionalDataPanel: (state) => {
    return state.user ? state.user.pantry_item_optional_data_panel : null
  },

  tasks: (state) => {
    let tasks = []
    const id = state.user.current_pantry
    const pantry = state.pantries[id] ? (state.pantries[id].pantryItems ? state.pantries[id].pantryItems.docs : []) : []
    if (pantry.length === 0) {
      tasks.push("Add some items to your pantry")
    }

    // Add a few items to the pantry
    // Create a recipe
    // Discover a recipe and add it to a recipe book
    // share a recipe book
    // share a pantry
    // make a recipe
    return tasks
  },

  searchQuery: (state) => {
    return state.searchQuery
  },
  currentRecipe: (state) => {
    return state.currentRecipe
  },
  currentPantry: (state) => {
    if (!state.user.current_pantry) {
      warn("Somehow the user is trying to access currentPantry but it isn't set")
    }
    return state.user.current_pantry
  },
  areMultiplePantries: (state) => {
    return state.pantries.docs.length > 1
  },
  discoveredRecipes: (state) => {
    return state.discoveredRecipes
    // Needs to use the current search query to pull recipes from the cloud
    // let recipes = [];
    // // need to bind a subset of recipes based on the query
    // if (state.recipeBooks[id] && state.recipeBooks[id].recipes) {
    //   recipes = state.recipeBooks[id].recipes.docs;
    //   if (state.searchQuery) {
    //     // could get fancy here with the search query.
    //     // Could break it up into keys:
    //     //    "ingredient: butter"
    //     //    "tag: keto"
    //     //    "calories < 1000"
    //     // Could also do exclusions "-ingredient: nuts"
    //     // Could do boolean:
    //     //    "ingredient: chocolate, tag: keto, calories < 500"
    //     //    "ingredient: oats | ingredient: flour" (might need to do "OR" or some other easier way to do 'or')
    //     //    Will need to allow for grouping "(ingredient: oats OR ingredient: flour), tag:keto" - LOL
    //     recipes = recipes.filter(recipe => {
    //       debug(recipe);
    //       let matching_ingredient = recipe.ref.ingredients.some(ingredient => {
    //         return ingredient.name.toLowerCase().includes(state.searchQuery);
    //       });
    //       return (
    //         recipe.ref.name.toLowerCase().includes(state.searchQuery) ||
    //         matching_ingredient
    //       );
    //     });
    //   }
    // }
    // return recipes;
  },
  discoveredRecipe: (state) => (id) => {
    return state.discoveredRecipes[id]
  },
  moreToDiscover: (state) => {
    return state.moreToDiscover
  },

  recipeBooks: (state) => {
    return state.recipeBooks.docs ? state.recipeBooks.docs : []
  },
  recipeBooksContainingRecipe: (state, getters) => (recipeId) => {
    const containtingRecipeBooks = getters.recipeBooks.filter((recipeBook) =>
      getters.recipeBookRecipes(recipeBook.id).some((bookRecipe) => {
        return bookRecipe.id == recipeId
      })
    )
    return containtingRecipeBooks
  },
  recipeBooksNotContainingRecipe: (state, getters) => (recipeId) => {
    const containingBooks = getters.recipeBooksContainingRecipe(recipeId)
    return getters.recipeBooks.filter((x) => !containingBooks.some((y) => y.id === x.id))
  },
  recipeBook: (state, getters) => (recipeBookId) => {
    const foundRecipeBook = getters.recipeBooks.find((recipeBook) => recipeBook.id === recipeBookId)
    if (foundRecipeBook !== undefined) {
      return foundRecipeBook.ref
    }
    return undefined
    // if (state.recipeBooks.docs) {
    //   // TODO: find a way to access bound doc by id, not index or looping
    //   // until I find a way to better access a recipeBook in docs
    //   // loop through until the requested id is found return that recipeBook
    //   for (const doc of state.recipeBooks.docs) {
    //     if (doc.id === recipeBookId) {
    //       return doc.ref
    //     }
    //   }
    // }
    // return null
  },
  recipeBookMembers: (state) => (id) => {
    return state.recipeBooks[id] ? state.recipeBooks[id].members : []
  },
  recipeBookRecipes: (state) => (id) => {
    return state.recipeBooks[id] ? (state.recipeBooks[id].recipes ? state.recipeBooks[id].recipes.docs : []) : []
  },
  recipeBookRecipesFiltered: (state) => (id) => {
    let filteredResults = []
    if (state.recipeBooks[id] && state.recipeBooks[id].recipes) {
      filteredResults = state.recipeBooks[id].recipes.docs
      if (state.searchQuery) {
        // could get fancy here with the search query.
        // Could break it up into keys:
        //    "ingredient: butter"
        //    "tag: keto"
        //    "calories < 1000"
        // Could also do exclusions "-ingredient: nuts"
        // Could do boolean:
        //    "ingredient: chocolate, tag: keto, calories < 500"
        //    "ingredient: oats | ingredient: flour" (might need to do "OR" or some other easier way to do 'or')
        //    Will need to allow for grouping "(ingredient: oats OR ingredient: flour), tag:keto" - LOL
        filteredResults = filteredResults.filter((recipe) => {
          debug(recipe)
          let matching_ingredient = recipe.ref.ingredients.some((ingredient) => {
            return ingredient.name.toLowerCase().includes(state.searchQuery)
          })

          return recipe.ref.name.toLowerCase().includes(state.searchQuery) || matching_ingredient
        })
      }
    }
    return filteredResults
  },
  recipeFromRecipeBook: (state, getters) => (params) => {
    return getters.recipeBookRecipes(params.recipeBookId).find((recipe) => recipe.id === params.recipeId).ref
  },

  // Pantries
  pantries: (state) => {
    // debug("getter pantries docs: ")
    // debug(state.pantries.docs)
    return state.pantries.docs ? state.pantries.docs : []
  },
  pantry: (state) => (pantryId) => {
    if (state.pantries.docs) {
      // until I find a way to better access a pantry in docs
      // loop through until the requested id is found
      // return that pantry
      for (const doc of state.pantries.docs) {
        if (doc.id === pantryId) {
          return doc.ref
        }
      }
    }
    return null
  },
  pantryMembers: (state) => (id) => {
    return state.pantries[id] ? state.pantries[id].members : []
  },
  pantryPantryItems: (state) => (id) => {
    return state.pantries[id] ? (state.pantries[id].pantryItems ? state.pantries[id].pantryItems.docs : []) : []
  },
  pantryPantryItemsFiltered: (state) => (id) => {
    // debug("Query changed, returning updated search results")
    let filteredResults = []
    if (state.pantries[id] && state.pantries[id].pantryItems) {
      if (state.searchQuery) {
        filteredResults = state.pantries[id].pantryItems.docs.filter((item) => {
          return item.aliases.some((alias) => alias.name.toLowerCase().includes(state.searchQuery))
        })
      } else {
        filteredResults = state.pantries[id].pantryItems.docs
      }
    }
    return filteredResults

    // Tried to be clever and sort the list after it was pulled from the DB, but since it is bound
    // every time something is updated this will rerun, having the same effect as sorting the binding results.
    // I was trying to get it so that it would sort once on page load, then leave the items there until refresh.
    // This would require some kind of merging of the old array and the new results and that is too complicated
    // for what was supposed to be a simple feature.

    // // check for user sort prefernce. currently defaulting to most recent at the top
    // debug(filteredResults)
    // let sortedResults = filteredResults.sort((a, b) => {
    //   if (a.updatedAt !== undefined) {
    //     if (b.updatedAt !== undefined) {
    //       if (a.updatedAt > b.updatedAt) {
    //         return -1
    //       } else if (b > a) {
    //         return 1
    //       } else {
    //         return 0
    //       }
    //     } else {
    //       return -1 // a should be before b
    //     }
    //   } else {
    //     if (b.updatedAt !== undefined) {
    //       return 1 // b should be before a
    //     }
    //   }
    //   return 0 // order doesn't matter, neither has a stored update time
    // })
    // debug(sortedResults)
    // return sortedResults
  },
  pantryItemBeingEdited: (state) => {
    return state.pantryItemBeingEdited
  },
  currentPantryPantryItem: (state) => (pantryIndex) => {
    // TODO: find a way to access bound doc by id, not index or looping
    return state.pantries[state.user.current_pantry].pantryItems.docs[pantryIndex]
  },
  pantryItemFromPantry: (state) => (params) => {
    const pantryId = params.pantryId
    const pantryItemId = params.pantryItemId
    // debug("data: pantryItemFromPantry: " + pantryId + " | " + pantryItemId);
    if (
      pantryId !== undefined &&
      pantryItemId !== undefined &&
      state.pantries[pantryId] !== undefined &&
      state.pantries[pantryId].pantryItems &&
      state.pantries[pantryId].pantryItems.docs !== undefined
    ) {
      const pantryItems = state.pantries[pantryId].pantryItems.docs
      // debug("data: pantryItemFromPantry: looping over pantryItems");
      // TODO: find a way to access bound doc by id, not index or looping
      for (const pantryItem of pantryItems) {
        // debug("data: pantryItemFromPantry: " + pantryItem.id + " ==? " + pantryItemId);
        // debug(pantryItem)
        if (pantryItem.id === pantryItemId) {
          // debug("data: pantryItemFromPantry: pantryItem found!");
          return pantryItem
        }
      }
    }
    return null
  },
  ingredientIsInCurrentPantry: (state) => (ingredient) => {
    if (
      state.pantries[state.user.current_pantry] &&
      state.pantries[state.user.current_pantry].pantryItems &&
      state.pantries[state.user.current_pantry].pantryItems.docs
    ) {
      for (const pantryItem of state.pantries[state.user.current_pantry].pantryItems.docs) {
        for (const alias of pantryItem.aliases) {
          if (ingredient.name === alias.name) {
            if (pantryItem.formComplexity === 0) {
              if (pantryItem.status === "in_stock") {
                return { status: "full", pantryItem: pantryItem }
              } else if (pantryItem.status === "need_more") {
                return { status: "partial", pantryItem: pantryItem }
              } else if (pantryItem.status === "out_of_stock") {
                return { status: "not in stock", pantryItem: pantryItem }
              }
            } else if (pantryItem.formComplexity === 1) {
              if (pantryItem.measurement === ingredient.measurement && pantryItem.quantity >= ingredient.quantity) {
                return { status: "full", pantryItem: pantryItem }
              } else {
                return { status: "partial", pantryItem: pantryItem }
              }
            }
          }
        }
      }
    }
    return { status: "not found", pantryItem: null }
  },

  userShoppingList: (state) => {
    return state.user.shopping_list
  },

  pantryShoppingList: (state) => (pantry_id) => {
    let shopping_list = []
    // debug("data: pantryShoppingList: for: " + pantry_id)
    // debug(state.pantries)
    if (state.pantries[pantry_id]) {
      for (const item of state.pantries[pantry_id].pantryItems.docs) {
        if (item.formComplexity === /* simple */ 0) {
          if (item.status === "need_more") {
            shopping_list.push(item)
          }
        } else if (item.formComplexity === /* advanced */ 1) {
          if (item.quantity <= 0) {
            shopping_list.push(item)
          }
        }
      }
    }
    return shopping_list
  },

  // returns all unique ingredients found in any recipe book recipe or pantry
  ingredientsSmartSuggestions:
    (state) =>
    ({ include_id_if_possible, exclude_pantry_items }) => {
      let suggestions = {}
      // looking at all recipe books, and all recipes in them for ingredients sounds expensive
      // maybe we should not do this? Seems like a nice features though....make sure it only happens when needed?
      for (const recipeBookId in state.recipeBooks) {
        if (recipeBookId !== "docs") {
          if (state.recipeBooks[recipeBookId].recipes !== undefined) {
            state.recipeBooks[recipeBookId].recipes.docs.forEach((recipe) => {
              // it is possible that ingredients have not initialized yet, check that they are really there
              if (recipe.ref.ingredients !== undefined) {
                recipe.ref.ingredients.forEach((ingredient) => {
                  suggestions[ingredient.name] = { id: undefined }
                })
              }
            })
          }
        }
      }

      for (const pantryId in state.pantries) {
        if (pantryId !== "docs") {
          if (state.pantries[pantryId].pantryItems !== undefined) {
            state.pantries[pantryId].pantryItems.docs.forEach((pantryItem) => {
              if (exclude_pantry_items !== undefined && exclude_pantry_items === true) {
                delete suggestions[pantryItem.name]
              } else {
                suggestions[pantryItem.name] = { id: pantryItem.id }
              }
            })
          }
        }
      }

      let sortedKeys = Object.keys(suggestions).sort(function (a, b) {
        return a.toLowerCase().localeCompare(b.toLowerCase())
      })

      let sortedSuggestions = []
      for (let index in sortedKeys) {
        let key = sortedKeys[index]
        if (include_id_if_possible) {
          sortedSuggestions.push({ name: key, id: suggestions[key].id })
        } else {
          sortedSuggestions.push({ name: key })
        }
      }
      return sortedSuggestions
    }
}

const mutations = {
  setUser(state, user) {
    state.user = user
  },

  // This is a little wonky, but I don't see a better way.
  // Basically, below we bind recipe_books collection to recipeBooks.docs
  // docs must be an array, because that is how vuexfire binds it, couldn't find a way to get it to be an object/map
  // so docs mirrors recipe_books
  // to get the subcollections of recipe_books docs, we set each one up as its own object, but that object doesn't have access to its actual props
  // instead, those are still stored and synced in docs, so we just store a refernce to which doc index a specific recipe book is stored at.
  addDocsIndexToRecipeBook: (state, { id, index }) => {
    // Using Vue.set() allows this to be a dynamically added reactive attribute. Cannot be root level in state.
    // https://vuejs.org/v2/guide/list.html#Array-Change-Detection
    if (!state.recipeBooks[id]) {
      Vue.set(state.recipeBooks, id, {})
    }
    if (!state.recipeBooks[id].docsIndex) {
      Vue.set(state.recipeBooks, id, {
        ...state.recipeBooks[id],
        docsIndex: index
      })
    }
  },
  addMembersToRecipeBook: (state, id) => {
    if (!state.recipeBooks[id]) {
      Vue.set(state.recipeBooks, id, {})
    }
    if (!state.recipeBooks[id].members) {
      Vue.set(state.recipeBooks, id, { ...state.recipeBooks[id], members: [] })
    }
  },
  addRecipesToRecipeBook: (state, id) => {
    if (!state.recipeBooks[id]) {
      Vue.set(state.recipeBooks, id, {})
    }
    if (!state.recipeBooks[id].recipes) {
      Vue.set(state.recipeBooks, id, {
        ...state.recipeBooks[id],
        recipes: { docs: [] }
      })
    }
  },

  setSearchQuery: (state, query) => {
    state.searchQuery = query ? query.toLowerCase() : null

    // update anything that is referencing the search query
  },

  // Pantries
  flagPantriesAsBound: (state) => {
    state.pantriesAreBound = true
  },
  addMembersToPantry: (state, id) => {
    if (!state.pantries[id]) {
      Vue.set(state.pantries, id, {})
    }
    if (!state.pantries[id].members) {
      Vue.set(state.pantries, id, { ...state.pantries[id], members: [] })
    }
  },
  addPantryItemsToPantry: (state, id) => {
    if (!state.pantries[id]) {
      Vue.set(state.pantries, id, {})
    }
    if (!state.pantries[id].pantryItems) {
      Vue.set(state.pantries, id, {
        ...state.pantries[id],
        pantryItems: { docs: [] }
      })
    }
  },
  setPantryItemBeingEdited: (state, pantryItem) => {
    // this duplicates the item into pantryItemBeingEdited so that we don't modify real values while editing
    debug("data: setPantryItemBeingEdited: ")
    debug(pantryItem)
    let pantryItemToEdit = { ...pantryItem }
    if (pantryItem.id !== undefined) {
      // this appears to be redundant, but trust me, it isn't
      // something about the id not being a real property and therefore not being exploded in the step above
      // all I know is if you take it out, editing pantry items no longer works....
      pantryItemToEdit.id = pantryItem.id
    }
    if (pantryItem.formComplexity === undefined) {
      pantryItemToEdit.formComplexity = state.user.pantry_item_form_complexity_default
    }
    state.pantryItemBeingEdited = Object.assign({}, pantryItemToEdit)
  },
  clearPantryItemBeingEdited: (state) => {
    debug("data: clearPantryItemBeingEdited")
    state.pantryItemBeingEdited = {}
  },

  clearDiscoveredRecipes: (state) => {
    state.discoveredRecipes = {}
    state.moreToDiscover = true
    state.lastDiscoveredRecipe = null
  },
  addDiscoveredRecipe: (state, { id, discoveredRecipe }) => {
    Vue.set(state.discoveredRecipes, id, discoveredRecipe)
  },
  setLastDiscoveredRecipe: (state, lastDiscoveredRecipe) => {
    state.lastDiscoveredRecipe = lastDiscoveredRecipe
  },
  setMoreToDiscover: (state, moreToDiscover) => {
    state.moreToDiscover = moreToDiscover
  }
}

const actions = {
  async onUserChanged(context, user) {
    let userData = null
    if (user) {
      // User logged in
      const userRef = firestore.collection("users").doc(user.uid)
      const serverTimestamp = FieldValue.serverTimestamp()

      // Sync logged in user with corresponding user in firestore database
      let isNewUser
      await userRef.get().then(async (userDoc) => {
        log("Creating new user")
        isNewUser = !userDoc.exists
        if (isNewUser) {
          // New User
          isNewUser = true
          userData = {}
          userData.id = user.uid // might not need this?
          userData.name = user.displayName
          userData.email = user.email
          userData.image_url = user.photoURL
          userData.updated_at = serverTimestamp
          userData.created_at = serverTimestamp
          userData.recipe_book_count = 1
          userData.pantry_count = 1
          userData.pantry_item_form_complexity_default = 0
          userData.shopping_list = []

          // Add default Pantry
          const pantryData = {
            name: "My Pantry",
            member_count: 1
          }
          const pantryRef = await firestore.collection("pantries").add(pantryData)
          userData.current_pantry = pantryRef.id

          await userRef.set(userData)

          // Add default Recipe Book
          const recipeBookData = {
            name: "My Recipes",
            member_count: 1
          }
          const recipeBookRef = await firestore.collection("recipe_books").add(recipeBookData)
          await userRef.collection("recipe_books").doc(recipeBookRef.id).set({ ref: recipeBookRef })
          await recipeBookRef.collection("members").doc(userRef.id).set({ ref: userRef })

          await userRef.collection("pantries").doc(pantryRef.id).set({ ref: pantryRef })
          await pantryRef.collection("members").doc(userRef.id).set({ ref: userRef })
        } else {
          // Returning User
          userData = userDoc.data()

          userData.updated_at = serverTimestamp
          userRef.update(userData)
        }
      })
      if (userData.id) {
        // confirmed need await here or user is null while we try to use it
        await context.dispatch("bindUser", userData.id)
      }
      // bind user
    }
    // commit("setUser", userData)
  },

  bindUser: firestoreAction(async ({ bindFirestoreRef }, userId) => {
    warn("User bound")
    await bindFirestoreRef("user", firestore.collection("users").doc(userId))
  }),

  unbindAll: firestoreAction(({ state, unbindFirestoreRef }) => {
    // This gets triggered on logout. Clears data and unhooks listeners trying to keep app in sync.

    // for each recipe book
    for (const recipeBook of state.recipeBooks.docs) {
      unbindFirestoreRef(`recipeBooks.${recipeBook.id}.members`)
      unbindFirestoreRef(`recipeBooks.${recipeBook.id}.recipes.docs`)
    }
    unbindFirestoreRef("recipeBooks.docs")

    // for each pantry
    for (const pantry of state.pantries.docs) {
      unbindFirestoreRef(`pantries.${pantry.id}.members`)
      unbindFirestoreRef(`pantries.${pantry.id}.pantryItems.docs`)
    }
    unbindFirestoreRef("pantries.docs")

    unbindFirestoreRef("currentRecipe")

    // signout clears user permissions, but not the user object, which we use for determining if someone is logged in or not.
    unbindFirestoreRef("user")
  }),

  async savePantryItemOptionalDataPanel({ state }, isVisible) {
    if (state.user) {
      const userRef = firestore.collection("users").doc(state.user.id)
      await userRef.set({ pantry_item_optional_data_panel: isVisible }, { merge: true })
    } else {
      console.error("Cannot save optional data panel display state without being logged in!")
    }
  },

  async saveColor({ state }, { key, newColor }) {
    if (state.user) {
      const userRef = firestore.collection("users").doc(state.user.id)
      let colors = { colors: { [key]: newColor } }
      debug("Colors: ")
      debug(colors)
      await userRef.set(colors, { merge: true })
    } else {
      console.error("Cannot save color without being logged in!")
    }
  },

  bindCurrentRecipe: firestoreAction(async ({ bindFirestoreRef }, recipeId) => {
    // if (state.currentRecipe !== null){
    //   debug("data: CurrentRecipe already bound.");
    // }
    // else{
    debug("date: Binding CurrentRecipe: " + recipeId)
    await bindFirestoreRef("currentRecipe", firestore.collection("recipes").doc(recipeId))
    // }
  }),

  bindRecipeBooks: firestoreAction(async ({ state, bindFirestoreRef }) => {
    if (state.recipeBooks.docs.length !== 0) {
      // warn("data: RecipeBooks already bound.")
    } else {
      debug("data: Binding RecipeBooks")
      const user_id = state.user.id
      await bindFirestoreRef("recipeBooks.docs", firestore.collection("users").doc(user_id).collection("recipe_books"))
    }
  }),

  setRecipeBooksDocIndex: (context, params) => {
    context.commit("addDocsIndexToRecipeBook", params)
  },

  bindRecipeBookMembers: firestoreAction(async ({ commit, bindFirestoreRef }, recipeBookId) => {
    if (state.recipeBooks[recipeBookId] && state.recipeBooks[recipeBookId].members) {
      // warn("data: RecipeBook " + recipeBookId + " already has members bound.")
    } else {
      debug("data: Binding RecipeBook(" + recipeBookId + ") Members")
      // We have to create the location we will be storing a collection first before puting it there. This will allow it to be reactive
      commit("addMembersToRecipeBook", recipeBookId)
      // Make sure 'recipeBooks.id.members' exists as a reactive attribute already
      await bindFirestoreRef(
        `recipeBooks.${recipeBookId}.members`,
        firestore.collection("recipe_books").doc(recipeBookId).collection("members")
      )
    }
  }),

  bindRecipeBookRecipes: firestoreAction(async ({ commit, bindFirestoreRef }, recipeBookId) => {
    if (state.recipeBooks[recipeBookId] && state.recipeBooks[recipeBookId].recipes) {
      // debug("data: RecipeBook " + recipeBookId + " already has recipes bound.")
    } else {
      debug("data: Binding RecipeBook(" + recipeBookId + ") Recipes")
      commit("addRecipesToRecipeBook", recipeBookId)
      await bindFirestoreRef(
        `recipeBooks.${recipeBookId}.recipes.docs`,
        firestore.collection("recipe_books").doc(recipeBookId).collection("recipe_pages")
      )
    }
  }),

  bindAllRecipeBookRecipes: firestoreAction(async ({ commit, getters, bindFirestoreRef }) => {
    for (const recipeBook of getters.recipeBooks) {
      if (state.recipeBooks[recipeBook.id] && state.recipeBooks[recipeBook.id].recipes) {
        // debug("data: RecipeBook " + recipeBook.id + " already has recipes bound.")
      } else {
        debug("data: Binding RecipeBook(" + recipeBook.id + ") Recipes")
        commit("addRecipesToRecipeBook", recipeBook.id)
        await bindFirestoreRef(
          `recipeBooks.${recipeBook.id}.recipes.docs`,
          firestore.collection("recipe_books").doc(recipeBook.id).collection("recipe_pages")
        )
      }
    }
  }),

  setSearchQuery: (context, query) => {
    context.commit("setSearchQuery", query)
  },

  // updateDiscoveredRecipesQuery: firestoreAction(context => {
  //   // it will be tricky to make the search smart, but for now we just search recipe names
  //   // turns out there is no built in search function for partial strings or "string contains"
  //   // https://gotm.io/devblog/fuzzy-search-with-firestore-and-flexsearch/
  //   return context.bindFirestoreRef(
  //     "discoveredRecipes",
  //     firestore.collection("recipes").where("public", "==", true).limit(1)
  //   );
  // }),

  discoverMoreRecipes: async (context) => {
    // adding to already pulled recipes
    // let discoveredRecipes = context.state.discoveredRecipes;
    const num_to_load = 1
    const starting_size = Object.keys(context.state.discoveredRecipes).length
    let expected_total = starting_size + num_to_load

    let query = firestore.collection("recipes").where("discoverable", "==", true)
    if (context.state.searchQuery) {
      debug("Using search query: " + context.state.searchQuery)
      warn("Searching through shared recipes is not currently enabled.")
      // search is caps sensitive....fml
      // this looks helpful for partial text searching:
      // https://medium.com/feedflood/filter-by-search-keyword-in-cloud-firestore-query-638377bf0123
      // query = query.where("name", "==", "added on phone");
    }
    if (context.state.lastDiscoveredRecipe) {
      query = query.startAfter(context.state.lastDiscoveredRecipe)
    } else {
      // this is the first set of results, clear any old results.
      context.commit("clearDiscoveredRecipes")
      expected_total = num_to_load
    }

    await query
      .limit(num_to_load)
      .get()
      .then((querySnapshot) => {
        context.commit("setLastDiscoveredRecipe", querySnapshot.docs[querySnapshot.docs.length - 1])
        for (const recipeDoc of querySnapshot.docs) {
          context.commit("addDiscoveredRecipe", {
            id: recipeDoc.id,
            discoveredRecipe: recipeDoc.data()
          })
        }
      })

    const ending_size = Object.keys(context.state.discoveredRecipes).length
    // There is a problem when the last possible one to load makes these equal.
    // It will think there are more available but when it goes to pull them there won't be anything.
    if (ending_size < expected_total) {
      context.commit("setMoreToDiscover", false)
    }
  },

  // Pantries
  bindPantries: firestoreAction(async (context) => {
    if (state.pantriesAreBound) {
      warn("data: Pantries already bound.")
      return new Promise((resolve) => {
        resolve()
      })
    } else {
      debug("data: Binding Pantries")
      debug(context.state.user)
      const user_id = context.state.user.id
      await context.bindFirestoreRef("pantries.docs", firestore.collection("users").doc(user_id).collection("pantries"))
      context.commit("flagPantriesAsBound")
      debug("data: Done Binding Pantries")
    }
  }),
  setCurrentPantry: async (context, pantryId) => {
    if (state.user) {
      debug("data: setCurrentPantry: saving new current pantry to cloud")
      const userRef = firestore.collection("users").doc(state.user.id)
      await userRef.set({ current_pantry: pantryId }, { merge: true })
    } else {
      warn("Cannot save current pantry without being logged in!")
    }
  },
  bindPantryMembers: firestoreAction(async ({ commit, state, bindFirestoreRef }, pantryId) => {
    if (state.pantries[pantryId] && state.pantries[pantryId].members) {
      warn("data: Pantry " + pantryId + " already has members bound.")
    } else {
      debug("data: Binding Pantry (" + pantryId + ") Members")
      // We have to create the location we will be storing a collection first before puting it there. This will allow it to be reactive
      commit("addMembersToPantry", pantryId)
      // Make sure 'recipeBooks.id.members' exists as a reactive attribute already
      await bindFirestoreRef(
        `pantries.${pantryId}.members`,
        firestore.collection("pantries").doc(pantryId).collection("members")
      )
    }
  }),
  bindPantryPantryItems: firestoreAction(async ({ commit, bindFirestoreRef }, pantryId) => {
    if (state.pantries[pantryId] && state.pantries[pantryId].pantryItems) {
      warn("data: Pantry " + pantryId + " already has pantry items bound.")
    } else {
      // debug("data: Binding Pantry (" + pantryId + ") PantryItems")
      commit("addPantryItemsToPantry", pantryId)
      await bindFirestoreRef(
        `pantries.${pantryId}.pantryItems.docs`,
        // sort here if we want them to always update their position (seems like it could be confusing to people)
        // Could have a toggle at the top for "Recently updated at top"
        firestore.collection("pantries").doc(pantryId).collection("pantry_items") //.orderBy("displayName", "asc")
      )
    }
  }),

  editPantryItem: ({ commit }, pantryItem) => {
    commit("setPantryItemBeingEdited", pantryItem)
  },
  savePantryItemBeingEdited: async ({ commit, dispatch, state }, pantryId) => {
    dispatch("savePantryItemToPantry", {
      pantryItem: state.pantryItemBeingEdited,
      pantryId
    })
    commit("clearPantryItemBeingEdited")
  },
  savePantryItemToPantry: async (context, { pantryItem, pantryId }) => {
    // console.warn("Saving pantry item to pantry with ID: " + pantryId)
    const pantryRef = firestore.collection("pantries").doc(pantryId)
    pantryItem.updatedAt = FieldValue.serverTimestamp()
    if (pantryItem.quantity !== undefined) {
      pantryItem.quantity = parseFloat(pantryItem.quantity)
    }
    if (pantryItem.id !== undefined) {
      // we are doing an edit
      let ref = pantryRef.collection("pantry_items").doc(pantryItem.id)
      // debug("data: Saving edited pantry item")
      // debug(pantryItem)
      await ref.set(pantryItem)
    } else {
      // this is a new pantry item
      let ref = pantryRef.collection("pantry_items").doc()
      // debug("data: Saving new pantry item")
      // debug(pantryItem)
      await ref.set(pantryItem)
      await pantryRef.update({ pantry_item_count: increment })
    }
    // commit("clearPantryItemBeingEdited")
  },
  addItemToUserShoppingList: async (context, item) => {
    if (state.user) {
      let list = []
      if (state.user.shopping_list !== undefined) {
        list = state.user.shopping_list
      }
      // would probably be more efficient to flag the item as either
      // custom or as a chosen API item instead of using measurements === undefined
      // as the indicator for what time of shopping item it is.
      // This would involve more API pings when moving the item to the pantry
      // so as long as this item contains all information necessary to build
      // a pantry item from, this should work fine.
      list.push(item)
      const userRef = firestore.collection("users").doc(state.user.id)
      await userRef.set({ shopping_list: list }, { merge: true })
    } else {
      warn("Cannot save shopping_list without being logged in!")
    }
  },
  removeItemsFromUserShoppingList: async (context, items_to_remove) => {
    if (state.user && state.user.shopping_list !== undefined) {
      const userRef = firestore.collection("users").doc(state.user.id)
      await userRef.set(
        {
          shopping_list: state.user.shopping_list.filter(
            (list_item) => !(list_item.name in items_to_remove && items_to_remove[list_item.name] === true)
          )
        },
        { merge: true }
      )
    } else {
      warn("Cannot save shopping_list without being logged in!")
    }
  },
  mergePantryItems: (context, { pantryItem, targetPantryItem, pantryId }) => {
    targetPantryItem.aliases = targetPantryItem.aliases.concat(pantryItem.aliases)
    console.log(targetPantryItem)
    context.dispatch("savePantryItemToPantry", {
      pantryItem: targetPantryItem,
      pantryId
    })
    context.dispatch("removePantryItemFromPantry", {
      pantryItemId: pantryItem.id,
      pantryId: pantryId
    })
  },
  addIngredientAsPantryItemAlias: (context, { ingredient, targetPantryItem, pantryId }) => {
    targetPantryItem.aliases.push({
      name: ingredient.name,
      measurements: ingredient.measurements
    })
    context.dispatch("savePantryItemToPantry", {
      pantryItem: targetPantryItem,
      pantryId
    })
  },
  addItemToCurrentPantry: (context, { item }) => {
    context.dispatch("addItemToPantry", {
      item: item,
      pantryId: context.getters.currentPantry
    })
  },
  addItemToPantry: (context, { item, pantryId }) => {
    // check to see if exact name matches and we are just out of stock, if so, toggle stock
    if (item.name === undefined) {
      warn("Name is required to add an item to pantry")
      return
    }
    if (item.measurements === undefined) {
      warn("Measurements are required to add an item to pantry")
      return
    }

    let item_to_add = {
      aliases: [item],
      displayName: item.name,
      formComplexity: 0,
      status: "in_stock"
    }

    if (item.measurement !== undefined) {
      item_to_add.measurement = item.measurement
    }

    context.dispatch("savePantryItemToPantry", {
      pantryItem: item_to_add,
      pantryId
    })
  },
  cyclePantryItemStatus: (context, { pantryItem, pantryId }) => {
    if (pantryItem.formComplexity === 0) {
      if (pantryItem.status === "in_stock") {
        pantryItem.status = "need_more"
      } else if (pantryItem.status === "need_more") {
        pantryItem.status = "out_of_stock"
      } else if (pantryItem.status === "out_of_stock") {
        pantryItem.status = "in_stock"
      } else {
        pantryItem.status = "in_stock"
      }
      context.dispatch("savePantryItemToPantry", { pantryItem, pantryId })
    } else {
      warn("Tried to change status on 'advanced' pantry item. Action prevented.")
    }
  },
  setPantryItemStatusToInStock: (context, { pantryItem, pantryId }) => {
    if (pantryItem.formComplexity === 0) {
      pantryItem.status = "in_stock"
      context.dispatch("savePantryItemToPantry", { pantryItem, pantryId })
    } else {
      warn("Tried to change status to 'In Stock' on 'advanced' pantry item. Action prevented.")
    }
  },
  setPantryItemStatusToOutOfStock: (context, { pantryItem, pantryId }) => {
    if (pantryItem.formComplexity === 0) {
      pantryItem.status = "out_of_stock"
      context.dispatch("savePantryItemToPantry", { pantryItem, pantryId })
    } else {
      warn("Tried to change status to 'Out of Stock' on 'advanced' pantry item. Action prevented.")
    }
  },
  updatePantryItemQuantity: (context, { pantryItem, quantity, pantryId }) => {
    if (pantryItem.formComplexity === 1) {
      debug("data: updatePantryItemQuantity: new quantity value: " + quantity)
      pantryItem.quantity = parseFloat(quantity)
      context.dispatch("savePantryItemToPantry", { pantryItem, pantryId })
    } else {
      warn("Tried to update quantity on 'basic' pantry item. Action prevented.")
    }
  },

  addImageToRecipe: async (context, { image, recipeRef }) => {
    // probably shouldn't do this unless the creation of the recipe was successful, that way we don't end up with orphaned images
    // Using the recipeRef ID, upload the image
    const metadata = {
      contentType: image !== undefined ? image.type : undefined
    }
    const ext = image.name.slice(image.name.lastIndexOf("."))
    const ref_path = "recipe_images/" + recipeRef.id + ext
    const uploadTask = await storage.ref().child(ref_path).put(image, metadata)

    const image_url = await uploadTask.ref.getDownloadURL()
    recipeRef.update({ image_ref: ref_path, image_url: image_url })
  },

  addExistingRecipeToRecipeBook: async (context, { recipeToAddId, recipeBookToAddTo }) => {
    const recipeRef = firestore.collection("recipes").doc(recipeToAddId)
    context.dispatch("addRecipeToRecipeBook", {
      recipeToAddRef: recipeRef,
      recipeBookToAddToId: recipeBookToAddTo.id
    })
  },

  addRecipeToRecipeBook: async (context, { recipeToAddRef, recipeBookToAddToId, batch }) => {
    debug("data: START Adding " + recipeToAddRef.id + " to " + recipeBookToAddToId)

    let batch_was_given = true
    if (batch === undefined) {
      batch_was_given = false
      batch = firestore.batch()
    }

    const recipeBookToAddToRef = firestore.collection("recipe_books").doc(recipeBookToAddToId)
    // increment the recipe book's recipe_count (so the book knows how many recipes it has)
    batch.set(recipeBookToAddToRef, { recipe_count: increment }, { merge: true })
    // increment the recipe's recipe_book_ref_count (so thre recipe knows how many books its in)
    batch.set(recipeToAddRef, { recipe_book_ref_count: increment }, { merge: true })
    // create a doc reference to this recipe inside the recipe book
    // add a recipe_page to the recipe book, it is a document containing only a reference to the recipe
    // I opted for a subcollection for the rare chance that 2 people would be adding the recipe to a book
    // at the same time, which with an array on the recipe_book, could cause a problem.
    const pageRef = recipeBookToAddToRef.collection("recipe_pages").doc(recipeToAddRef.id)
    // save the reference to the recipe to the new recipe_page
    await batch.set(pageRef, { ref: recipeToAddRef }, { merge: true })

    if (!batch_was_given) {
      debug("data: addRecipetoRecipeBook: commiting batch")
      await batch.commit()
    }

    debug("data: DONE Adding " + recipeToAddRef.id + " to " + recipeBookToAddToId)
  },

  // Removal/Delete Actions
  //
  // batch writes still count against usage as far as billing is concerned,
  // but using batch should produce less partially complete actions and leave the
  // database in a better state should something go wrong.
  removePantryItemFromPantry: (context, { pantryItemId, pantryId, batch }) => {
    let batch_was_given = true
    if (batch === undefined) {
      debug("data: removePantryItemFromPantry - no batch provided, create new one")
      batch_was_given = false
      batch = firestore.batch()
    }

    const pantryRef = firestore.collection("pantries").doc(pantryId)
    const pantryItemRef = pantryRef.collection("pantry_items").doc(pantryItemId)

    batch.update(pantryRef, { pantry_item_count: decrement })
    batch.delete(pantryItemRef)

    if (!batch_was_given) {
      batch.commit()
    }

    if (pantryItemId == context.state.pantryItemBeingEdited.id) {
      // If the item being deleted also happens to the be pantryItemBeingEdited
      // (such as in the case of merging from a PantryItemFormDialog),
      // we also need to clear the pantryItemBeingEdited since it has just been removed.
      context.commit("clearPantryItemBeingEdited")
    }
  },

  removeRecipeFromRecipeBook: async (context, { recipeToRemoveId, recipeBookToRemoveFromId, batch }) => {
    debug("data: Removing " + recipeToRemoveId + " from " + recipeBookToRemoveFromId)

    let batch_was_given = true
    if (batch === undefined) {
      batch_was_given = false
      batch = firestore.batch()
    }

    // decrement the recipe books recipe counter
    const recipeBookToRemoveFromRef = firestore.collection("recipe_books").doc(recipeBookToRemoveFromId)
    debug("data: About to batch.update: " + recipeToRemoveId)
    await batch.update(recipeBookToRemoveFromRef, { recipe_count: decrement })
    debug("data: After batch.update: " + recipeToRemoveId)
    // recipeBookToRemoveFromRef.update({recipe_count: decrement});

    // get all the recipe books that contain this recipe and tell them that it is no longer in this book
    // we have to do this because each book has pages, each page knows all the books its in
    // so when we take a page out of a book, we have to tell all the other pages that it isn't in that book anymore
    const recipePageToRemoveRef = recipeBookToRemoveFromRef.collection("recipe_pages").doc(recipeToRemoveId)
    // const owningRecipeBooks = await recipePageToRemoveRef.collection("owning_recipe_books").get()
    // debug(owningRecipeBooks)

    // debug("data: Before await Promise.all: " + recipeToRemoveId)

    // for (let i = 0; i < owningRecipeBooks.docs.length; i++) {
    //   const owningRecipeBookDoc = owningRecipeBooks.docs[i]
    //   // go into each book the recipe is still a part of, find the recipe page and remove a referene to this recipe book from its owners
    //   debug("data: owningRecipeBook " + owningRecipeBookDoc.id + " : " + recipeToRemoveId)
    //   const pageRef = firestore
    //     .collection("recipe_books")
    //     .doc(owningRecipeBookDoc.id)
    //     .collection("recipe_pages")
    //     .doc(recipeToRemoveId)

    //   // don't need to check if it is the last one, because the only case where that is true is when it is the one being removed
    //   await batch.update(pageRef, { owning_recipe_book_count: decrement })
    //   await batch.delete(pageRef.collection("owning_recipe_books").doc(recipeBookToRemoveFromId))
    //   // pageRef.update({owning_recipe_book_count: decrement});
    //   // pageRef.collection("owning_recipe_books").doc(recipeBookToRemoveFromId).delete();

    //   // remove all documents from the owning_recipe_books subcollection for the page we are deleting
    //   const ref = recipePageToRemoveRef.collection("owning_recipe_books").doc(owningRecipeBookDoc.id)
    //   batch.delete(ref)
    //   // ref.delete();
    // }
    // debug("data: After await Promise.all: " + recipeToRemoveId)
    // now recipePageToRemove.owning_recipe_books should be empty, and we used it to clean up other recipe book page references

    // check if the recipe page is part of any recipe book
    // if (recipePageToRemoveDoc.data().owning_recipe_book_count > 1){
    //   batch.update(recipePageToRemoveRef, {owning_recipe_book_count: decrement});
    // }
    // else {
    // batch.delete(recipePageToRemoveRef);
    // recipePageToRemoveRef.delete();
    //   // recipe no longer belongs to any (of this user's ??? maybe?) recipe books and delete the doc
    // }

    // until I can get the batch deletes working in the same batch as the subcollection deletes + decrements
    // I need to commit before deleting, and in this function it will happen a few times, which is terrible
    // and kind of defeats the purpose of batching. Whole batch should be commited, or none of it.

    // TODO: This is causing a problem. I think this function is getting called twice using the same batch
    // which is a problem since that batch gets committed in one of the calls and reset
    // but the second call doesn't have the new batch, it has the old one, that has already been committed...

    await batch
      .commit()
      .then(() => {
        // destroy recipe page from recipe book subcollection
        recipePageToRemoveRef.delete()
        batch = firestore.batch()
      })
      .catch((error) => {
        console.error(error.message)
      })

    // get the root level recipe
    const recipeRef = firestore.collection("recipes").doc(recipeToRemoveId)
    const recipeDoc = await recipeRef.get()
    debug("data: Got the Recipe: " + recipeRef.id)
    debug(recipeDoc.data())
    if (recipeDoc.data().recipe_book_ref_count > 1) {
      // decrement reference count on the root level recipe
      batch.update(recipeRef, { recipe_book_ref_count: decrement })
    } else {
      // this is the last reference, delete the root level recipe
      try {
        // delete the image associate with this recipe
        await storage.ref().child(recipeDoc.data().image_ref).delete()
      } catch (error) {
        console.error(error.message)
      }

      // delete the root level recipe
      batch.delete(recipeRef)
    }

    if (!batch_was_given) {
      batch.commit()
    }
  },

  // eslint-disable-next-line
  addUserToPantry: async ({ state }, { userId, pantryId }) => {
    const batch = firestore.batch()

    const userRef = firestore.collection("users").doc(userId)
    const pantryRef = firestore.collection("pantries").doc(pantryId)

    await batch.set(pantryRef.collection("members").doc(userId), {
      ref: userRef
    })
    await batch.update(pantryRef, { member_count: increment })

    await batch.set(userRef.collection("pantries").doc(pantryId), {
      ref: pantryRef
    })
    await batch.update(userRef, { pantry_count: increment })

    batch.commit()
  },

  // eslint-disable-next-line
  removeUserFromPantry: async ({ dispatch, state }, { userId, pantryId }) => {
    let batch = firestore.batch()

    // remove pantry from users pantries
    const userRef = firestore.collection("users").doc(userId)
    const userPantryRef = userRef.collection("pantries").doc(pantryId)

    batch.update(userRef, { pantry_count: decrement })
    batch.delete(userPantryRef)

    // remove the reference to the user from the pantry
    const pantryRef = firestore.collection("pantries").doc(pantryId)
    const pantryDoc = await pantryRef.get()

    batch.delete(pantryRef.collection("members").doc(userId))

    // decrement pantry member count
    // if there is only 1 member left, remove all recipes from the book and then delete it
    if (pantryDoc.data().member_count > 1) {
      batch.update(pantryRef, { member_count: decrement })
    } else {
      // remove all pantry_items from this pantry, otherwise we would have orphaned subcollections
      const pantryItems = await pantryRef.collection("pantry_items").get()

      for (let i = 0; i < pantryItems.docs.length; i++) {
        dispatch("removePantryItemFromPantry", {
          pantryItemId: pantryItems.docs[i].id,
          pantryId: pantryId,
          batch
        })
      }

      // Destroy root level pantry
      // batch.delete(pantryRef);
      // There is a big problem that occurs when we try to have all the pantry item deletes in a batch along with the pantry delete. A whole lot of red firebase errors that puts the user into a super broken state sometimes (requires a lot of manual work in the database to clean it all up including deleting the user......very bad.)
      // INTERNAL ASSERTION FAILED: Transform results missing for TransformMutation.
      // maybe because the writes are included in the delete and the deletes end up executing first, or some interaction
      // with the FieldValue.increment() function and the deletes, idk what it is...

      await batch
        .commit()
        .then(() => {
          pantryRef.delete()
          batch = firestore.batch()
        })
        .catch((error) => {
          console.error(error.message)
        })
    }

    batch.commit()
  },

  // eslint-disable-next-line
  addUserToRecipeBook: async ({ state }, { userId, recipeBookId }) => {
    const batch = firestore.batch()

    const userRef = firestore.collection("users").doc(userId)
    const recipeBookRef = firestore.collection("recipe_books").doc(recipeBookId)

    await batch.set(recipeBookRef.collection("members").doc(userId), {
      ref: userRef
    })
    await batch.update(recipeBookRef, { member_count: increment })

    await batch.set(userRef.collection("recipe_books").doc(recipeBookId), {
      ref: recipeBookRef
    })
    await batch.update(userRef, { recipe_book_count: increment })

    batch.commit()
  },

  // eslint-disable-next-line
  removeUserFromRecipeBook: async ({ dispatch }, { userId, recipeBookId }) => {
    let batch = firestore.batch()
    debug(userId)
    // remove recipe book from users recipe books
    const userRef = firestore.collection("users").doc(userId)
    const userRecipeBookRef = userRef.collection("recipe_books").doc(recipeBookId)
    // userRecipeBookRef.delete();
    batch.delete(userRecipeBookRef)
    // userRef.update({recipe_book_count: decrement});
    batch.update(userRef, { recipe_book_count: decrement })

    // remove the reference to the user from the recipe book
    const recipeBookRef = firestore.collection("recipe_books").doc(recipeBookId)
    const recipeBookDoc = await recipeBookRef.get()
    batch.delete(recipeBookRef.collection("members").doc(userId))
    // recipeBookRef.collection('members').doc(userId).delete();

    // decrement recipe book member count
    // if there is only 1 member left, remove all recipes from the book and then delete it
    if (recipeBookDoc.data().member_count > 1) {
      batch.update(recipeBookRef, { member_count: decrement })
      // recipeBookRef.update({member_count: decrement});
    } else {
      // remove all recipes from this recipe book
      const recipeBookRecipes = await recipeBookRef.collection("recipe_pages").get()

      // can't use a .forEach because it doesn't allow async/await in its body:
      // https://zellwk.com/blog/async-await-in-loops/
      for (let i = 0; i < recipeBookRecipes.docs.length; i++) {
        // each of these is a reference to a recipe in the root level recipes collection
        // which means recipeBookRecipeRef only contains id, path, and its parent.
        // If we want any actual data for the recipe we need to get it using an id.
        console.warn("data: About to call dispatch function.")
        await dispatch("removeRecipeFromRecipeBook", {
          recipeToRemoveId: recipeBookRecipes.docs[i].id,
          recipeBookToRemoveFromId: recipeBookId,
          batch
        })
        console.warn("data: After calling dispatch function.")
      }

      await batch
        .commit()
        .then(() => {
          // destroy root level recipe book
          recipeBookRef.delete()
          batch = firestore.batch()
        })
        .catch((error) => {
          console.error(error.message)
        })
    }

    batch.commit()
  }
}

export default {
  // namespaced: true,
  state,
  getters,
  mutations,
  actions
}
