import Vue from "vue"
import Vuetify from "vuetify/lib"

Vue.use(Vuetify)

const vuetify = new Vuetify({
  theme: {
    themes: {
      light: {
        // primary: '#1976D2',
        // secondary: '#424242',
        // accent: '#82B1FF',
        // error: '#FF5252',
        // info: '#2196F3',
        // success: '#4CAF50',
        // warning: '#FFC107',

        // default colors for the site
        primary: "#2E9F9E", //'#1976D2',
        secondary: "#D4CBBE", //'#424242',
        accent: "#FF8659", //'#82B1FF',

        cancelColor: "#424242",
        confirmColor: "#4caf50",
        dangerColor: "#ff6f6a"
      },
      dark: {}
    }
  }
})

export default vuetify
