import Vue from "vue"
import Router from "vue-router"
import store from "@/store"

Vue.use(Router)

let router = new Router({
  mode: "history",
  base: process.env.BASE_URL,
  routes: [
    {
      path: "/discover",
      name: "discover",
      component: () => import("./views/Discover.vue"),
      meta: {
        requiresAuth: true
      }
    },
    {
      path: "/",
      name: "home",
      component: () => import("./views/Home.vue"),
      meta: {
        requiresAuth: true
      }
    },
    {
      path: "/login",
      name: "login",
      component: () => import("./views/Login.vue"),
      meta: {
        requiresGuest: true
      }
    },
    {
      path: "/meal_planning",
      name: "meal_planning",
      component: () => import("./views/MealPlanning.vue"),
      meta: {
        requiresAuth: true
      }
    },
    {
      path: "/pantries",
      name: "pantries",
      component: () => import("./views/Pantries.vue"),
      meta: {
        requiresAuth: true
      }
    },
    {
      path: "/pantry/:id",
      name: "pantry",
      component: () => import("./views/Pantry.vue"),
      meta: {
        requiresAuth: true
      }
    },
    {
      path: "/recipe_books",
      name: "recipe_books",
      component: () => import("./views/RecipeBooks.vue"),
      meta: {
        requiresAuth: true
      }
    },
    {
      path: "/recipe_book/:id",
      name: "recipe_book",
      component: () => import("./views/RecipeBook.vue"),
      meta: {
        requiresAuth: true
      }
    },
    {
      path: "/recipe/:id",
      name: "recipe",
      component: () => import("./views/Recipe.vue")
      // meta: {
      //   requiresAuth: true
      // }
    },
    {
      path: "/recipeBook/:recipeBookId/recipe/:id",
      name: "recipe_book_recipe",
      component: () => import("./views/Recipe.vue")
      // meta: {
      //   requiresAuth: true
      // }
    },
    {
      path: "/sandbox",
      name: "sandbox",
      component: () => import("./views/Sandbox.vue")
    },
    {
      path: "/settings",
      name: "settings",
      component: () => import("./views/Settings.vue"),
      meta: {
        requiresAuth: true
      }
    },
    {
      path: "/shopping_lists",
      name: "shopping_lists",
      component: () => import("./views/ShoppingLists.vue"),
      meta: {
        requiresAuth: true
      }
    }
  ]
})

router.beforeEach((to, from, next) => {
  const requiresAuth = to.matched.some((record) => record.meta.requiresAuth)
  const requiresGuest = to.matched.some((record) => record.meta.requiresGuest)
  const isLoggedIn = store.getters["isLoggedIn"]
  // console.warn("router.beforeEach")
  // console.warn(isLoggedIn)
  // console.warn(store.state.data.user)
  // console.warn(from)
  // console.warn(to)

  // alert("Did you mean to navigate?")

  let route = null
  // if (sessionStorage.getItem("requestedRoute") !== null){
  //   route = sessionStorage.requestedRoute;
  //   sessionStorage.removeItem("requestedRoute");
  // }

  if (isLoggedIn) {
    if (requiresAuth) {
      console.warn("isLoggedIn && requiresAuth")
    } else if (requiresGuest) {
      console.warn("isLoggedIn && requiresGuest")
      route = "/"
    } else {
      console.warn("isLoggedIn && no meta requirements")
    }
  } else {
    if (requiresAuth) {
      console.warn("NOT isLoggedIn && requiresAuth")
      route = "/login?signInSuccessUrl=" + window.location.pathname
    } else if (requiresGuest) {
      console.warn("NOT isLoggedIn && requiresGuest")
    } else {
      console.warn("NOT isLoggedIn && no meta requirements")
    }
  }

  if (route !== null) {
    next(route)
  } else if (next !== undefined) {
    next()
  }
})

export default router
