import Vue from "vue"
import vuetify from "@/plugins/vuetify"
// import "material-design-icons-iconfont/dist/material-design-icons.css"
import store from "./store"
import App from "./App.vue"
import router from "./router"
import { auth } from "./fb"
import { firestorePlugin } from "vuefire"
import "./registerServiceWorker"
import { debug } from "@/logger"

Vue.use(firestorePlugin)
Vue.config.productionTip = false

let app
debug("main: Waiting for firebase.auth to initialize")
auth.onAuthStateChanged(async (user) => {
  // User is initially set to null when auth is created which triggers this function.
  // This function is also called when the user logs in, and when they log out.
  debug("main: User Auth state changed.")
  await store.dispatch("onUserChanged", user)
  debug("main: Done handling changed user: ")
  debug(user)

  if (!app) {
    // If the app hasn't been created yet, do that here.
    // After the app is created it auto redirects to "/" (I couldn't find a way to stop this)
    debug("main: Creating Vue app")
    app = new Vue({
      store,
      router,
      vuetify,
      render: (h) => h(App)
    }).$mount("#app")
  } else {
    // If the app already exists and the user has changed that suggests either a login or logout.
    // In which case we refresh the page and let the router handle where the user lands.
    // In practice, I believe the only way to hit this is by logging out.
    debug("main: Should only be hit after logging out")
    router.go()
  }
})
