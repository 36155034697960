// Version 9 of firebase released on August 25, 2021 and broke old imports.
// compat versions will work with existing code, but I should update to new API:
// https://firebase.google.com/docs/web/modular-upgrade#refactor_to_the_modular_style

import firebase from "firebase/compat/app"
import "firebase/compat/firestore"
import "firebase/compat/auth"
import "firebase/compat/storage"
import "firebase/compat/analytics"

// Initialize Firebase
const config = {
  apiKey: "AIzaSyBulM_w6kdO4bUvh0MhWY_6xLjGbwDUWlQ",
  authDomain: "shelf-backend.firebaseapp.com",
  databaseURL: "https://shelf-backend.firebaseio.com",
  projectId: "shelf-backend",
  storageBucket: "shelf-backend.appspot.com",
  messagingSenderId: "720001570562",
  appId: "1:720001570562:web:4ada3d2d996d42a1",
  measurementId: "G-1X8N4W3PN9"
}

firebase.initializeApp(config)

export const firestore = firebase.firestore()
firestore.enablePersistence().catch((error) => {
  if (error.code == "failed-precondition") {
    // Multiple tabs open, persistence can only be enabled in one tab at a time
  } else if (error.code == "unimplemented") {
    // The current browser does not support all of the features required to enable persistence
  }
})
export const auth = firebase.auth()
export const storage = firebase.storage()
export const analytics = firebase.analytics()
export const FieldValue = firebase.firestore.FieldValue

export default firebase
