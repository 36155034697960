import { register } from "register-service-worker"
import { debug } from "@/logger"

// Incredible guide on service workings and Vue:
// https://levelup.gitconnected.com/vue-pwa-example-298a8ea953c9

const notifyUserAboutUpdate = (worker) => {
  // for cool custom alerts and notifications, look into using https://alertifyjs.com/
  let result = confirm("New update found! Would you like to reload?")
  if (result) {
    worker.postMessage({ action: "skipWaiting" })
  }
}

if (process.env.NODE_ENV === "production") {
  register(`${process.env.BASE_URL}service-worker.js`, {
    ready() {
      debug("App is being served from cache by a service worker.\n" + "For more details, visit https://goo.gl/AFskqB")
    },
    registered(registration) {
      debug("Service worker has been registered.")
      debug(registration)
    },
    cached() {
      debug("Content has been cached for offline use.")
    },
    updatefound(registration) {
      debug("New content is downloading.")
      debug(registration)
    },
    updated(registration) {
      debug("New content is available; please refresh.")
      notifyUserAboutUpdate(registration.waiting)
    },
    offline() {
      debug("No internet connection found. App is running in offline mode.")
    },
    error(error) {
      console.error("Error during service worker registration:", error)
    }
  })

  let refreshing = false
  navigator.serviceWorker.addEventListener("controllerchange", () => {
    if (refreshing) return
    window.location.reload()
    refreshing = true
  })
}
