<template>
  <v-select
    :value="selectedPantry"
    :items="pantries"
    label="Current Pantry"
    solo
    @change="selectPantry"
    hide-details
    v-if="$store.getters['areMultiplePantries']"
    dense
  >
    <!-- Don't need an attach for this one yet either. Kinda broke it when I tried actually. -->
  </v-select>
</template>

<script>
export default {
  computed: {
    pantries() {
      const pantry_docs = this.$store.getters["pantries"]
      const pantries = pantry_docs.map((doc) => {
        return { text: doc.ref.name, value: doc.id }
      })
      return pantries
    },
    selectedPantry() {
      return this.$store.getters["currentPantry"]
    }
  },
  methods: {
    selectPantry(pantry_id) {
      this.$store.dispatch("setCurrentPantry", pantry_id)
    }
  },
  async mounted() {
    await this.$store.dispatch("bindPantries")
    for (const pantry of this.pantries) {
      await this.$store.dispatch("bindPantryPantryItems", pantry.value)
    }
  }
}
</script>

<style></style>
