// export const firestore = firebase.firestore()
// firestore.enablePersistence().catch((error) => {
//   if (error.code == "failed-precondition"){
//     // Multiple tabs open, persistence can only be enabled in one tab at a time
//   }
//   else if ( error.code == "unimplemented") {
//     // The current browser does not support all of the features required to enable persistence
//   }
// })
// export const log = firebase.auth()
// export const storage = firebase.storage()
// export const FieldValue = firebase.firestore.FieldValue

const logging = true
function log(message, logging = true) {
  if (logging) {
    console.log(JSON.parse(JSON.stringify(message)))
  }
}

// function debug(message, logging = true) {
//     if (logging){
//         console.debug(message);
//     }
// }
//* https://stackoverflow.com/a/32928812/1878664
if (logging) var debug = console.debug.bind(window.console)
else debug = function () {}

function warn(message, logging = true) {
  if (logging) {
    console.warn(JSON.parse(JSON.stringify(message)))
  }
}
export { log, debug, warn }
