<template>
  <v-app>
    <v-navigation-drawer v-model="drawer" app temporary class="primary" v-if="isLoggedIn">
      <v-container>
        <v-row justify="center">
          <v-col class="mt-5 text-center">
            <v-avatar size="100" class="grey lighten-2">
              <img :src="user.image_url" />
            </v-avatar>
            <p class="white--text subheading my-1">{{ user.name }}</p>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <CurrentPantrySelector />
          </v-col>
        </v-row>
      </v-container>

      <v-list>
        <v-list-item-group>
          <v-list-item v-for="link in links" :key="link.icon" :to="link.route">
            <v-list-item-icon>
              <v-icon class="white--text">{{ link.icon }}</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title class="white--text">{{ link.text }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>
      </v-list>

      <v-container>
        <v-row justify="center">
          <v-col cols="10" class="text-center">
            <v-btn @click="logout()" color="error" block>
              <span>Logout</span>
              <v-icon right>mdi-logout</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-container>
    </v-navigation-drawer>

    <v-snackbar v-model="snackbar" :timeout="snackbarDuration" top>
      {{ snackbarMessage }}
      <v-btn @click="snackbar = false" icon>
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-snackbar>

    <v-app-bar app v-if="isLoggedIn" class="primary" ref="header" collapse-on-scroll dark color="white">
      <v-app-bar-nav-icon @click="drawer = !drawer"></v-app-bar-nav-icon>

      <v-toolbar-title v-if="!isSearchActive">{{ title }}</v-toolbar-title>
      <v-spacer v-if="!isScrolled"></v-spacer>

      <v-text-field solo light clearable :loading="loadingSearchResults" hide-details="" ref="searchBar"
        style="max-width: 0%; opacity: 0" @input="onSearchBarInput" @change="onSearchBarChange" @blur="onSearchBarBlur"
        v-if="isSearchEnabled" prepend-inner-icon="mdi-magnify" :label="searchLabel">
      </v-text-field>
      <v-btn icon v-if="isSearchEnabled && !isSearchActive" @click="showSearchBar">
        <v-icon>mdi-magnify</v-icon>
      </v-btn>
      <!-- <v-btn v-if="!isLoggedIn && false" to="/login" color="primary" dark>
        <span>Login</span>
        <v-icon right>account_circle</v-icon>
      </v-btn> -->

      <!-- <v-menu open-on-hover bottom offset-y transition="slide-y-transition">
        <template v-slot:activator="{ on }">
          <v-btn icon fab v-on="on" class="mr-0">
            <v-avatar size="40">
              <img :src="user.image_url">
            </v-avatar>
          </v-btn>
        </template>
        <v-list>
          <v-list-item-group>
            <v-list-item v-for="link in links" :key="link.icon" :to="link.route">
              <v-list-item-icon>
                <v-icon>{{ link.icon }}</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title v-text="link.text"></v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-item-group>
          <v-list-item>
            <v-list-item-content>              
              <v-btn @click="logout()" color="error">
                <span>Logout</span>
              <v-icon right>mdi-logout</v-icon>
            </v-btn>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-menu> -->
    </v-app-bar>

    <!-- <v-app-bar app v-if="!isLoggedIn" class="primary">
      <v-toolbar-title>Shelf</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn to="/login" color="accent" dark>
        <span>Login</span>
        <v-icon right>mdi-account-circle</v-icon>
      </v-btn>
    </v-app-bar> -->

    <!-- Sizes your content based upon application components -->
    <v-main class="secondary">
      <router-view></router-view>
      <div style="height: 100px">
        <!-- Gives extra space so that once scrolled to the bottom, content can go above any potential floating action button -->
      </div>
      <FeedbackDialog />
    </v-main>

    <v-footer app v-if="inDevEnv()" style="padding: 0 10px">
      <span style="font-size: 12px">Dev info: size({{ this.$vuetify.breakpoint.name }})</span>
    </v-footer>
  </v-app>
</template>

<script>
/* eslint-disable */
import { auth } from "@/fb"
import { debug } from "@/logger"
import CurrentPantrySelector from "@/components/CurrentPantrySelector"
import FeedbackDialog from "@/components/FeedbackDialog"
export default {
  components: { CurrentPantrySelector, FeedbackDialog },
  name: "App",
  data() {
    return {
      title: "",
      drawer: false,
      isScrolled: false,
      isSearchEnabled: false,
      searchLabel: "Search...",
      isSearchActive: false,
      submitSearchTimeout: null,
      loadingSearchResults: false,
      appBarAnimation: null,
      searchBarAnimation: null,
      links: [
        { text: "Home", icon: "mdi-home", route: "/" },
        {
          text: "Recipe Books",
          icon: "mdi-bookshelf",
          route: "/recipe_books"
        },
        // mdi-food
        // mdi-food-variant
        // mdi-hamburger
        // mdi-fridge-outline
        { text: "Pantries", icon: "mdi-fridge", route: "/pantries" },
        // mdi-cart-outline
        {
          text: "Shopping Lists",
          icon: "mdi-cart",
          route: "/shopping_lists"
        },
        // { text: "Meal Planning", icon: "", route: "/meal_planning"},
        // mdi-compass
        { text: "Discover Recipes", icon: "mdi-chef-hat", route: "/discover" },
        { text: "Settings", icon: "mdi-settings", route: "/settings" }
      ],
      snackbar: false,
      snackbarMessage: "No message given to snackbar :(",
      snackbarDuration: 10000
    }
  },
  computed: {
    user() {
      return this.$store.getters["user"]
    },
    isLoggedIn() {
      return this.$store.getters["isLoggedIn"]
    },
    colors() {
      return this.$store.getters["colors"]
    }
  },
  async mounted() {
    this.$root.$on("show-snackbar", (message, duration) => {
      this.snackbar = true
      if (message !== undefined) {
        this.snackbarMessage = message
      }
      if (duration !== undefined) {
        this.snackbarDuration = duration
      }
    })
    this.$root.$on("update-title", (title) => {
      this.title = title
    })
    this.$root.$on("enable-search", (hint) => {
      this.isSearchEnabled = true
      this.searchLabel = hint
    })

    debug("App Mounted()")
    if (this.isLoggedIn) {
      // This is the best way to watch a value on a $ref since they must be loaded before the watch can be set up.
      this.$watch(
        () => {
          return this.$refs.header.currentScroll
        },
        (val) => {
          // debug("Scroll changed! " + val)
          if (val > 0) {
            this.isScrolled = true
          } else {
            this.isScrolled = false
          }
        }
      )
    }
  },
  methods: {
    inDevEnv() {
      // warn("dev mode?")
      // debug(process.env)
      return process.env.NODE_ENV === "development" && this.$store.getters["user"] && this.$store.getters["user"].isDev // vs "production", both have BASE_URL: "/"
    },
    logout() {
      this.$store.dispatch("unbindAll")
      auth.signOut()
    },
    onSearchBarInput(params) {
      clearTimeout(this.submitSearchTimeout)
      this.submitSearchTimeout = setTimeout(() => {
        this.$store.dispatch("setSearchQuery", params)
      }, 500)
    },
    onSearchBarChange() {
      // Called when text is submitted by pushing "Enter"
      this.$refs.searchBar.blur()
      if (this.isSearchActive && !this.$refs.searchBar.internalValue) {
        this.hideSearchBar()
      }
    },
    onSearchBarBlur() {
      if (this.isSearchActive && !this.$refs.searchBar.internalValue) {
        this.hideSearchBar()
      }
    },
    showSearchBar() {
      this.isSearchActive = true
      const appBarEffect = new KeyframeEffect(this.$refs.header.$el, [{ minWidth: "100px" }, { minWidth: "300px" }], {
        duration: 500,
        fill: "both"
      })
      this.appBarAnimation = new Animation(appBarEffect)

      const searchBarEffect = new KeyframeEffect(
        this.$refs.searchBar.$el,
        [
          { maxWidth: "0px", opacity: "0" },
          { maxWidth: "100%", opacity: "1" }
        ],
        {
          duration: 500,
          fill: "both"
        }
      )
      this.searchBarAnimation = new Animation(searchBarEffect)

      this.appBarAnimation.play()
      this.searchBarAnimation.play()
      this.$nextTick(() => {
        this.$refs.searchBar.focus()
      })
    },
    hideSearchBar() {
      debug("Hiding Search Bar")
      if (this.appBarAnimation) {
        this.appBarAnimation.reverse()
      }
      if (this.searchBarAnimation) {
        this.searchBarAnimation.reverse()
      }
      this.isSearchActive = false
    }
  },
  created() {
    // this will keep any existing, overriding where applicable and adding any new colors that the user stored on their account.
    this.$vuetify.theme.themes.light = {
      ...this.$vuetify.theme.themes.light,
      ...this.colors
    }
  },
  watch: {
    // turns out we are not bound to watch the user, set that up, and we should be able to watch for changes to colors made externally
    // currently colors only update on refresh.
    "this.$store.state.user.colors"(newValue) {
      debug("Colors changed!")
      debug(newValue)
      this.$vuetify.theme.themes.light = {
        ...this.$vuetify.theme.themes.light,
        ...newValue
      }
    },
    $route() {
      this.searchLabel = "Search..."
      this.isSearchEnabled = false
    }
  }
}
</script>

<style>
.v-list-item--disabled {
  background-color: #f3e0cc;
}

.v-list-item--disabled .v-list-item__content .v-list-item__title {
  color: rgba(0, 0, 0, 0.7);
}
</style>
